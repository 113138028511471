import { Modal } from "react-bootstrap";
import profile from "../assets/images/profile.png";
import { fileTypeToImage } from "../util/Utilities";
import { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import EditExpiredAtComponent from "./EditExpiredAtComponent";
import blank from '../assets/images/file_types/blank.png';
import EditVersionComponent from "./EditVersionComponent";

const DetailFileModal = ({ show, file, setShow, onUpdate, hiddenUpdate }) => {

    const [showFileSizeIn, setShowFileSizeIn] = useState('mb');

    const [imageToDisplay, setImageToDisplay] = useState(blank);

    const [editExpiredAt, setEditExpiredAt] = useState(false);

    const [editVersion, setEditVersion] = useState(false);

    useEffect(() => {
        if (file) {
            fileTypeToImage(file?.extension, file?.id).then((response) => {
                setImageToDisplay(response);
            });
        }
    }, [file])

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Detalles del archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Tipo:</b>
                                </td>
                                <td style={{ fontSize: 14, textTransform: 'capitalize', textAlign: 'end' }}>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <img src={imageToDisplay} alt={file?.name} style={{ width: 25, height: 20 }} />
                                        <p className="m-0 ms-2">
                                            {file?.extension}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Nombre del archivo:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {file?.name}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Versión del archivo:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {
                                        editVersion ?
                                            <>
                                                <EditVersionComponent
                                                    file={file}
                                                    onUpdate={onUpdate}
                                                    setEditVersion={setEditVersion}
                                                />
                                            </>
                                            :
                                            <>
                                                {file?.version || '--'}
                                                {
                                                    file?.currentUserCanUpdate && !hiddenUpdate ?
                                                        <button className="btn btn-xs p-2" style={{ fontSize: 16 }} onClick={() => setEditVersion(true)}>
                                                            <MdModeEdit />
                                                        </button>
                                                        :
                                                        null
                                                }
                                            </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Prefijo del archivo:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {file?.prefix}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Ubicación:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {file?.path}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Tamaño:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }} className="text-dark">
                                    {file?.sizes?.[showFileSizeIn] || '--'}
                                    {
                                        file?.sizes ?
                                            <select className="text-dark" style={{ border: 'none' }} value={showFileSizeIn} onChange={(e) => setShowFileSizeIn(e.target.value)}>
                                                {Object.keys(file?.sizes)?.map(value => {
                                                    return (
                                                        <option value={value}>
                                                            {value}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            :
                                            null
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Estatus:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                    {file?.status === 'vigente' && <p className="m-0 text-success">{file?.status || '--'}</p>}
                                    {file?.status === 'por vencer' && <p className="m-0 text-warning">{file?.status || '--'}</p>}
                                    {file?.status === 'vencido' && <p className="m-0 text-danger">{file?.status || '--'}</p>}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Fecha de expiración:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {
                                        editExpiredAt ?
                                            <>
                                                <EditExpiredAtComponent
                                                    file={file}
                                                    onUpdate={onUpdate}
                                                    setEditExpiredAt={setEditExpiredAt}
                                                />
                                            </>
                                            :
                                            <>
                                                {file?.expire_at || '--'}
                                                {
                                                    file?.currentUserCanUpdate && !hiddenUpdate ?
                                                        <button className="btn btn-xs p-2" style={{ fontSize: 16 }} onClick={() => setEditExpiredAt(true)}>
                                                            <MdModeEdit />
                                                        </button>
                                                        :
                                                        null
                                                }
                                            </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Propietario:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <img src={file?.user?.imagePath || profile} alt={file?.user?.name} style={{ width: 40, height: 40, borderRadius: '100%' }} />
                                        <p className="m-0 ms-2">
                                            {file?.user?.name}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 14 }}>
                                    <b>Fecha de creación:</b>
                                </td>
                                <td style={{ fontSize: 14, textAlign: 'end' }}>
                                    {file?.created_at || '--'}
                                    {
                                        file?.currentUserCanUpdate
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn text-dark me-1 fw-bold" onClick={() => setShow(false)}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DetailFileModal;