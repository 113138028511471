import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import useAxios from "../hooks/useAxios";
import { Spinner } from "react-bootstrap";



const EditVersionComponent = ({ file, onUpdate, setEditVersion }) => {

    const [currentVersion, setCurrentVersion] = useState('');

    const [{ data, loading }, updateFile] = useAxios({ url: `/files/${file?.id}/version`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (file) setCurrentVersion(file?.version);
    }, [file])

    const handleSubmit = () => {
        updateFile({
            data: {
                version: currentVersion
            }
        }).then((response) => {
            onUpdate?.(response?.data?.data);
            setEditVersion(false);
        });
    }

    return (
        <div class="input-group">
            <input type="number" class="form-control" value={currentVersion} onChange={(e) => setCurrentVersion(e.target.value)} />
            <button disabled={loading} title="Cancelar" class="btn btn-xs btn-outline-danger p-2" type="button" style={{ fontSize: 14 }} onClick={() => setEditVersion(false)}>
                <MdOutlineCancel />
            </button>
            <button disabled={loading} title="Enviar" class="btn btn-xs btn-outline-primary p-2" type="button" style={{ fontSize: 14 }} onClick={handleSubmit}>
                {
                    loading ?
                        <Spinner variant="ligth" style={{ width: 14, height: 14 }} animation="grow" />
                        :
                        <FaCheck />
                }
            </button>
        </div>
    )
}

export default EditVersionComponent;